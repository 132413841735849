export default {
    en: {
        lang:'LANGUAGE',
        question: 'Which language?',
        select: 'EN'
    },
    nl: {
        lang:'TAAL',
        question: 'Welke taal heeft je voorkeur?',
        select: 'NL'
    }
};